import * as React from "react";
import Footer from "../sections/Footer/Footer";
import Navbar from "../sections/Navbar/Navbar";
import RegBanner from "../sections/RegBanner/RegBanner";
import Sponsors from "../sections/Sponsors/Sponsors";
import { Helmet } from "react-helmet";
import "../fonts/stylesheet.css";

// styles
const text = {
  fontFamily: "Manrope",
};

// markup
const SponsorsPage = () => {
  return (
    <main style={text}>
      {" "}
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          Conquest: India's First and Largest Student-run Startup Accelerator
        </title>
      </Helmet>
      <Navbar />
      <Sponsors />
      <RegBanner />
      <Footer />
    </main>
  );
};

export default SponsorsPage;
